import React from "react";

import { Site } from "../../dtos/site";
import { ActiveStatus } from "../../enums/ActiveStatus";

import { Banner } from "./Banner";

interface Props {
    site: Site | undefined;
}

export function SiteInactiveBanner({ site }: Props) {
    if (!site || site.activeStatus === undefined) return null;

    if (site.activeStatus === ActiveStatus.Active) return null;

    return (
        <Banner message="Site inactive. You may encounter limited functionality for certain features." />
    );
}
