export enum SiteEventName {
    SystemRestarted = 55,
    SystemShutdown = 60,
    SiteOffline = 70,
    SiteOnline = 80,
}

export const SiteEventNameDisplay = new Map([
    [SiteEventName.SystemRestarted, 'System Restarted'],
    [SiteEventName.SystemShutdown, 'System Shutdown'],
    [SiteEventName.SiteOffline, 'Site Offline'],
    [SiteEventName.SiteOnline, 'Site Online'],
]);
