import { Button } from "@material-ui/core";
import React, { useCallback, useState } from "react";

import { Dealer } from "../../../dtos/dealer";
import { UserLevel } from "../../../enums/userLevel";
import { NewEmployeeForm } from "../employees/form";

interface Props {
    dealer: Dealer;
    onEmployeeCreated: () => void;
}

export function AddAdminUserButton({
    dealer,
    onEmployeeCreated,
}: Props) {
    const [dealerToAddEmployeeTo, setDealerToAddEmployeeTo] = useState<number | null>(null);

    const handleAddAdminUser = useCallback(() => {
        setDealerToAddEmployeeTo(dealer.id);
    }, [dealer.id]);

    return (
        <>
            <Button
                color="primary"
                variant="outlined"
                onClick={handleAddAdminUser}
            >
                Add Admin User
            </Button>
            {dealerToAddEmployeeTo && (
                <NewEmployeeForm
                    closeForm={() => setDealerToAddEmployeeTo(null)}
                    loadEmployees={onEmployeeCreated}
                    dealerId={dealerToAddEmployeeTo}
                    initPartial={{ userLevel: UserLevel.DealerAdmin }}
                />
            )}
        </>
    );
}