import React, { useEffect } from "react";

import { UserLevel } from "../../../enums/userLevel";
import { useAuth } from "../../../features/auth/context";
import { OrgSelect } from "../../shared/OrgSelect";

interface Props {
    selectedDealerId: number | undefined;
    selectedOrganizationId: number | undefined;
    selectedSiteId: number | undefined;
    selectedUserLevel: UserLevel | null;
    onChange: (value: {
        dealerId?: number | null;
        orgId?: number | null;
        siteId?: number | null;
    }) => void;
}

export function EmployeeOrgSelectors({
    selectedDealerId,
    selectedOrganizationId,
    selectedSiteId,
    selectedUserLevel,
    onChange,
}: Props) {
    const { isGlobalAdmin, isDealerAdmin, isOrgAdmin } = useAuth();

    useEffect(() => {
        switch (selectedUserLevel) {
            case UserLevel.GlobalAdmin:
                onChange({ dealerId: null, orgId: null, siteId: null });
                break;
            case UserLevel.DealerAdmin:
                onChange({ dealerId: selectedDealerId, orgId: null, siteId: null });
                break;
            case UserLevel.OrgAdmin:
                onChange({ dealerId: selectedDealerId, orgId: selectedOrganizationId, siteId: null });
                break;
            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUserLevel]);

    if (!(isGlobalAdmin || isDealerAdmin || isOrgAdmin)) {
        return null;
    }

    return (
        <OrgSelect
            ignoreOrgSiteContext={true}
            selectedDealerId={selectedDealerId}
            selectedOrgId={selectedOrganizationId}
            selectedSiteId={selectedSiteId}
            dealerSelectIsEnabled={
                isGlobalAdmin
                && selectedUserLevel !== null
                && selectedUserLevel !== UserLevel.GlobalAdmin
            }
            orgSelectIsEnabled={
                (isGlobalAdmin || isDealerAdmin)
                && selectedUserLevel !== null
                && [
                    UserLevel.SiteAdmin,
                    UserLevel.SiteEmployee,
                    UserLevel.OrgAdmin,
                ].includes(selectedUserLevel)
            }
            siteSelectIsEnabled={
                (isGlobalAdmin || isDealerAdmin || isOrgAdmin)
                && selectedUserLevel !== null
                && [UserLevel.SiteAdmin, UserLevel.SiteEmployee].includes(selectedUserLevel)
            }
            bypassSiteContext={true}
            onChange={onChange}
            descriptive={true}
        />
    );
}
