import React, { useState, useRef, useEffect } from 'react';
import { AjaxResult } from '../../../enums/ajaxResult';
import { ValidationErrors } from '../../shared/validationErrors';
import {
    Dialog,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    FormControl,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useApi } from '../../useApi';
import { PhoneNumberInput } from '../../shared/phoneNumberInput';
import { FormField } from '../../shared/formField';
import { makeStyles } from '@material-ui/styles';
import { FormSection } from '../../shared/formSection';

import { EmployeeOrgSelectors } from "./EmployeeOrgSelectors";
import { UserLevelSelector } from "./UserLevelSelector";
import { EmployeeCreateDto } from '../../../dtos/employee';

const EMAILS_MATCH_ERROR = "Emails do not match. Please ensure confirm email matches original.";
const MISSING_USER_TYPE_ERROR = "User type is required";

export const NewEmployeeForm = ({
    orgId,
    siteId,
    dealerId,
    closeForm,
    loadEmployees,
    initPartial = {},
}: {
    siteId?: number;
    orgId?: number;
    dealerId?: number;
    closeForm: () => void;
    loadEmployees: () => void;
    initPartial?: Partial<EmployeeCreateDto>;
}) => {
    const { employeeApi } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [edited, setEdited] = useState<EmployeeCreateDto>({
        address: "",
        phone: "",
        email: "",
        firstName: "",
        lastName: "",
        siteId: siteId,
        organizationId: orgId,
        dealerId: dealerId,
        userLevel: null,
        ...initPartial,
    });
    const [loading, setLoading] = useState<boolean>(false);

    const [confirmEmail, setConfirmEmail] = useState("");
    const [errors, setErrors] = useState<string[]>([]);

    useEffect(() => {
    }, []);

    const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(true);
    const [reAddDialogOpen, setReAddDialogOpen] = useState<boolean>(false);

    const dialogContentRef = useRef(null);

    const submit = () => {
        setErrors([]);

        if (!edited.userLevel) {
            setErrors([MISSING_USER_TYPE_ERROR]);
            return;
        }
        if (edited.email !== confirmEmail) {
            setErrors([EMAILS_MATCH_ERROR]);
            return;
        }

        setLoading(true);
        setErrors([]);
        employeeApi
            .create({
                ...edited,
                firstName: edited.firstName.trim(),
                lastName: edited.lastName.trim(),
            })
            .then((r) => {
                if (r.result === AjaxResult.Success) {
                    enqueueSnackbar("New employee has been created", { variant: "success" });
                    loadEmployees();
                    closeForm();
                } else if (r.result === AjaxResult.Forbidden) {
                    enqueueSnackbar(
                        `You do not have permission to create a ${edited.userLevel} user in this context`,
                        { variant: "error" },
                    );
                } else if (r.result === AjaxResult.Duplicate) {
                    setCreateDialogOpen(false);
                    setReAddDialogOpen(true);
                } else {
                    enqueueSnackbar("Failed to create employee", { variant: "error" });
                    if (r.messages) {
                        setErrors(r.messages);
                    }
                }
                setLoading(false);
            });
    };

    const submitRestore = () => {
        if (!edited.userLevel) {
            return;
        }
        setLoading(true);
        setErrors([]);
        employeeApi.restoreEmployee(edited).then((r) => {
            if (r.result === AjaxResult.Success) {
                enqueueSnackbar("Employee has been restored", { variant: "success" });
                loadEmployees();
                closeForm();
            } else if (r.result === AjaxResult.Forbidden) {
                enqueueSnackbar(
                    "You do not have permission to restore a user in this context",
                    { variant: "error" },
                );
            } else {
                enqueueSnackbar("Failed to restore employee", { variant: "error" });
                if (r.messages) {
                    setErrors(r.messages);
                }
            }
            setLoading(false);
        });
    };

    const submitDisabled = loading || !edited.firstName || !edited.lastName;

    return (
        <>
            <Dialog open={reAddDialogOpen} maxWidth="sm">
                <DialogContent>
                    <div>
                        A user with the email address: {edited.email} already exists, but has previously
                        been deleted. Would you like to restore?
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        onClick={submitRestore}
                    >
                        Submit
                    </Button>
                    <Button variant="contained" color="secondary" onClick={() => closeForm()}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={createDialogOpen} fullWidth={true} maxWidth="sm" ref={dialogContentRef}>
                <DialogContent>
                    <form>
                        <FormSection headerText="Contact Info">
                            <FormField label="First Name" required={true}>
                                <FormControl>
                                    <TextField
                                        placeholder="First Name"
                                        value={edited.firstName}
                                        onChange={(e) =>
                                            setEdited({ ...edited, firstName: e.currentTarget.value })
                                        }
                                        InputProps={{
                                            inputProps: { className: classes.input },
                                        }}
                                        variant="outlined"
                                    />
                                </FormControl>
                            </FormField>
                            <FormField label="Last Name" required={true}>
                                <FormControl>
                                    <TextField
                                        placeholder="Last Name"
                                        value={edited.lastName}
                                        onChange={(e) =>
                                            setEdited({ ...edited, lastName: e.currentTarget.value })
                                        }
                                        InputProps={{
                                            inputProps: { className: classes.input },
                                        }}
                                        variant="outlined"
                                    />
                                </FormControl>
                            </FormField>
                            <FormField label="Phone">
                                <FormControl>
                                    <TextField
                                        value={edited.phone}
                                        onChange={(e) =>
                                            setEdited({ ...edited, phone: e.currentTarget.value })
                                        }
                                        InputProps={{
                                            inputComponent: PhoneNumberInput,
                                            inputProps: { className: classes.input },
                                        }}
                                        variant="outlined"
                                    />
                                </FormControl>
                            </FormField>
                            <FormField label="Email" required={true}>
                                <FormControl>
                                    <TextField
                                        type="text"
                                        value={edited.email}
                                        placeholder="Email"
                                        onChange={(e) =>
                                            setEdited({ ...edited, email: e.currentTarget.value })
                                        }
                                        InputProps={{
                                            inputProps: { className: classes.input },
                                        }}
                                        variant="outlined"
                                    />
                                </FormControl>
                            </FormField>
                            <FormField label="Confirm Email" required={true}>
                                <FormControl>
                                    <TextField
                                        type="text"
                                        value={confirmEmail}
                                        placeholder="Confirm Email"
                                        onChange={(e) =>
                                            setConfirmEmail(e.currentTarget.value)
                                        }
                                        InputProps={{
                                            inputProps: { className: classes.input },
                                        }}
                                        variant="outlined"
                                    />
                                </FormControl>
                            </FormField>
                            <FormField label="Address">
                                <FormControl>
                                    <TextField
                                        type="text"
                                        value={edited.address}
                                        placeholder="Address"
                                        onChange={(e) =>
                                            setEdited({ ...edited, address: e.currentTarget.value })
                                        }
                                        InputProps={{
                                            inputProps: { className: classes.input },
                                        }}
                                        variant="outlined"
                                    />
                                </FormControl>
                            </FormField>

                            <FormField label="User Type" required={true}>
                                <UserLevelSelector
                                    value={edited.userLevel}
                                    onChange={(userLevel) => {
                                        setEdited({ ...edited, userLevel })
                                    }}
                                />
                            </FormField>

                            <EmployeeOrgSelectors
                                selectedDealerId={edited.dealerId}
                                selectedOrganizationId={edited.organizationId}
                                selectedSiteId={edited.siteId}
                                selectedUserLevel={edited.userLevel}
                                onChange={(selected) => {
                                    setEdited({
                                        ...edited,
                                        dealerId: selected.dealerId || undefined,
                                        organizationId: selected.orgId || undefined,
                                        siteId: selected.siteId || undefined,
                                    });
                                }}
                            />
                        </FormSection>
                        <ValidationErrors errors={errors} />
                    </form>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={submitDisabled}
                            onClick={submit}
                        >
                            Submit
                        </Button>
                        <Button variant="contained" color="secondary" onClick={() => closeForm()}>
                            Cancel
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    );
};

const useStyles = makeStyles({
    input: {
        padding: '5px',
    },
});
