import { makeStyles } from "@material-ui/core";
import React from "react";

import { useAuth } from "../../features/auth/context";
import { Search } from "../Search";

import { BannerAboutDeactivation } from "./BannerAboutDeactivation";
import { SiteInactiveBanner } from "./SiteInactiveBanner";
import { SiteOfflineBanner } from "./SiteOfflineBanner";
import { useSite } from "./useSite";

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.backgroundThemeColor2,
        padding: "5px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        justifyContent: "center",
        alignItems: "center",
    },
}));

export function TopBar() {
    const classes = useStyles();
    const site = useSite();
    const { isGlobalAdmin, isDealerAdmin } = useAuth();
    const showSearch = isDealerAdmin || isGlobalAdmin;

    return (
        <div className={classes.container}>
            <BannerAboutDeactivation />
            <SiteOfflineBanner site={site} />
            <SiteInactiveBanner site={site} />
            {showSearch && <Search />}
        </div>
    );
}
