import { Checkbox, FormControlLabel, TextField, makeStyles } from "@material-ui/core";
import React from "react";

import { MaintenanceWindow } from "../../../../dtos/site";

const DB_TIME_FORMAT_REG_EX = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/; // "00:00:00 - 23:59:59"
const INPUT_TIME_FORMAT_REG_EX = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/; // "00:00 - 23:59"

const useStyles = makeStyles(() => ({
    container: {
        marginLeft: "30px",
        marginBottom: "20px",
        maxWidth: "800px",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        gap: "10px",
    },
    timeTextField: {
        marginLeft: "10px",
    },
    timesContainer: {
        display: "flex",
        gap: "60px",
    },
    daysContainer: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr",
        columnGap: "15px",
        rowGap: "5px",
    },
}));

const DAYS = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"] as const;
const TIMES = ["startTime", "endTime"] as const;
const TIMES_LABELS = {
    "startTime": "Starts at",
    "endTime": "Ends at",
};
const DEFAULT_CONFIGURATION: MaintenanceWindow = {
    startTime: "00:00:00", // should be in DB_TIME_FORM_REG_EX
    endTime: "06:00:00", // should be in DB_TIME_FORM_REG_EX
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    sunday: true,
};

interface Props {
    configuration: MaintenanceWindow | undefined;
    setConfiguration: (value: MaintenanceWindow) => void;
}

export function MaintenanceWindowConfiguration({
    configuration = DEFAULT_CONFIGURATION,
    setConfiguration,
}: Props) {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.timesContainer}>
                {TIMES.map(time => (
                    <FormControlLabel
                        key={time}
                        control={
                            <TextField
                                id={time}
                                type="time"
                                defaultValue={getTimeValueForInput(configuration[time], time)}
                                className={classes.timeTextField}
                                inputProps={{ step: 300 }}
                                onChange={(e) => setConfiguration({
                                    ...configuration,
                                    [time]: getTimeValueForDB(e.target.value, time),
                                })}
                            />
                        }
                        label={TIMES_LABELS[time]}
                        labelPlacement="start"
                        style={{ marginLeft: 0 }}
                    />
                ))}
            </div>

            <div className={classes.daysContainer}>
                {DAYS.map((day) => (
                    <FormControlLabel
                        key={day}
                        control={
                            <Checkbox
                                value={configuration[day]}
                                color="primary"
                                onChange={(e) => setConfiguration({ ...configuration, [day]: e.target.checked })}
                                checked={configuration[day]}
                            />
                        }
                        label={day.charAt(0).toUpperCase() + day.slice(1)}
                    />
                ))}
            </div>
        </div>
    );
}

function getTimeValueForInput(unsafeValue: string | undefined, time: "startTime" | "endTime") {
    let safeValue = DEFAULT_CONFIGURATION[time];
    if (unsafeValue && DB_TIME_FORMAT_REG_EX.test(unsafeValue)) {
        safeValue = unsafeValue;
    }
    return safeValue.split(':').slice(0, 2).join(':');
}

function getTimeValueForDB(unsafeValue: string | undefined, time: "startTime" | "endTime") {
    let safeValue = DEFAULT_CONFIGURATION[time];
    if (unsafeValue && INPUT_TIME_FORMAT_REG_EX.test(unsafeValue)) {
        safeValue = unsafeValue;
    }
    return safeValue + ":00";
}
