import React, { useState } from "react";

import {
    EULA_PDF_URL,
    PRIVACY_POLICY_PDF_URL,
    WHATS_NEW_PAGE_URL,
} from "../constants";

import { PdfContentPopup } from "./shared/PdfContentPopup";

const containerStyle: React.CSSProperties = {
    padding: "5px 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 40,
};

export function FooterLinks() {
    return (
        <div style={containerStyle}>
            <Link href={WHATS_NEW_PAGE_URL} label="What's New" />

            <LinkWithPdfContentPopup
                href={PRIVACY_POLICY_PDF_URL}
                label="Privacy Policy"
            />

            <LinkWithPdfContentPopup
                href={EULA_PDF_URL}
                label="Terms and Conditions"
            />
        </div>
    );
}


const buttonLinkStyle: React.CSSProperties = {
    background: "none",
    border: "none",
    padding: 0,
    margin: 0,
    color: "inherit",
    textDecoration: "underline",
    cursor: "pointer",
    font: "inherit",
};

function LinkWithPdfContentPopup({
    href,
    label,
}: {
    href: string;
    label: string;
}) {
    const [open, setOpen] = useState(false);

    return (<>
        <button
            onClick={() => setOpen(prev => !prev)}
            style={buttonLinkStyle}
        >
            {label}
        </button>

        <PdfContentPopup
            open={open}
            setOpen={setOpen}
            title={label}
            href={href}
        />
    </>);
}

function Link({
    href,
    label,
}: {
    href: string;
    label: string;
}) {
    return (
        <a
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "inherit" }}
        >
            {label}
        </a>
    );
}
