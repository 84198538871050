import React, { useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Grid,
} from "@material-ui/core";
import { Document, Page } from "react-pdf/dist/entry.webpack";

export function PdfContentPopup({
    open,
    setOpen,
    title,
    href,
}: {
    open: boolean,
    setOpen: (open: boolean) => void;
    title: string;
    href: string;
}) {
    const [numPages, setNumPages] = useState<number | null>(null);

    const handleClose = () => {
        setOpen(false);
    };

    function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
        setNumPages(numPages);
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            disableScrollLock={true}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Grid container justify="center">
                    <Document
                        file={{ url: href }}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        {Array.apply(null, Array(numPages))
                            .map((x, i) => i + 1)
                            .map((page) => (
                                <Page key={page} pageNumber={page} width={800} />
                            ))
                        }
                    </Document>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};
