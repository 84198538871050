import React, { useState, ChangeEvent, useEffect } from 'react';
import useRouter from 'use-react-router';
import { FormSection } from '../../shared/formSection';
import { FormField } from '../../shared/formField';
import { AjaxResult } from '../../../enums/ajaxResult';
import { Customer } from '../../../dtos/customer';
import { Button, makeStyles, TextField, FormControl, Checkbox } from '@material-ui/core';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import { useSnackbar } from 'notistack';
import { useApi } from '../../useApi';
import { PhoneNumberInput } from '../../shared/phoneNumberInput';
import { navLinkUrl } from '../../../utils';
import { ValidationErrors } from '../../shared/validationErrors';

export const CustomerForm = ({
    customer,
    orgId,
    siteId,
    dealerId,
    close,
    callback,
}: {
    customer?: Customer;
    orgId?: number;
    siteId?: number | null;
    dealerId?: number;
    close: () => void;
    callback?: (newCustomer: Customer) => void;
}) => {
    const classes = useStyles();
    const { history } = useRouter();
    const { customerApi } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const [firstName, setFirstName] = useState(customer ? customer.firstName : '');
    const [lastName, setLastName] = useState(customer ? customer.lastName : '');
    const [phone, setPhone] = useState(customer ? customer.phone : '');
    const [primarySiteId] = useState(siteId || 0);
    const [email, setEmail] = useState(customer ? customer.email : '');
    const [address, setAddress] = useState(customer ? customer.address : '');
    const [notes, setNotes] = useState(customer ? customer.notes : '');
    const [uniqueId, setUniqueId] = useState(customer ? customer.uniqueId : '');
    const [isInactive, setIsInactive] = useState(customer ? customer.isInactive : false);
    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const submit = () => {
        setLoading(true);
        const requestData = {
            address,
            email,
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            fullName: '',
            organizationId: customer ? customer.organizationId : orgId || 0,
            primarySiteId,
            phone,
            notes,
            uniqueId,
            isInactive,
        };
        const httpMethod = customer
            ? () => customerApi.put(customer.id, requestData)
            : () => customerApi.post(requestData);
        httpMethod().then((r) => {
            if (r.result === AjaxResult.Success) {
                if (callback && r.data) {
                    callback(r.data);
                }
            } else {
                if (r.messages) {
                    setValidationErrors(r.messages);
                } else {
                    if (r.messages) {
                        setValidationErrors(r.messages);
                    } else {
                        switch (r.result) {
                            case AjaxResult.Forbidden:
                                enqueueSnackbar(
                                    'You do not have permission to add or edit a customer for this organization'
                                );
                                break;
                            default:
                                enqueueSnackbar('ERROR: Failed to save customer');
                        }
                    }
                }
            }
            setLoading(false);
        });
    };

    useEffect(() => {
        if (siteId && siteId !== primarySiteId) {
            history.push(navLinkUrl("home", dealerId, orgId, siteId));
        }
        // eslint-disable-next-line 
    }, [siteId]);

    const submitDisabled = loading || !firstName || !lastName;

    return (
        <div>
            <FormSection headerText="Contact Info">
                <FormField label="First Name" required={true}>
                    <TextField
                        type="text"
                        value={firstName}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setFirstName(e.currentTarget.value)
                        }
                        variant="outlined"
                        inputProps={{ className: classes.input }}
                    />
                </FormField>
                <FormField label="Last Name" required={true}>
                    <TextField
                        type="text"
                        value={lastName}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setLastName(e.currentTarget.value)
                        }
                        variant="outlined"
                        inputProps={{ className: classes.input }}
                    />
                </FormField>
                <FormField label="Phone">
                    <FormControl>
                        <TextField
                            value={phone}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                setPhone(e.currentTarget.value)
                            }
                            InputProps={{
                                inputComponent: PhoneNumberInput,
                                inputProps: { className: classes.input },
                            }}
                            variant="outlined"
                        />
                    </FormControl>
                </FormField>
                <FormField label="Email">
                    <TextField
                        type="text"
                        value={email}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setEmail(e.currentTarget.value)
                        }
                        variant="outlined"
                        InputProps={{
                            inputProps: { className: classes.input },
                        }}
                    />
                </FormField>
                <FormField label="Address">
                    <TextField
                        type="text"
                        value={address}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setAddress(e.currentTarget.value)
                        }
                        variant="outlined"
                        inputProps={{ className: classes.input }}
                    />
                </FormField>
                <FormField label="Unique ID">
                    <TextField
                        type="text"
                        value={uniqueId}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setUniqueId(e.currentTarget.value)
                        }
                        variant="outlined"
                        inputProps={{ className: classes.input }}
                    />
                </FormField>
                <ValidationErrors errors={validationErrors} />
            </FormSection>
            <FormSection headerText="Account Info">
                <FormField label="Notes">
                    <TextField
                        multiline
                        value={notes}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setNotes(e.currentTarget.value)
                        }
                        variant="outlined"
                        InputProps={{ className: classes.input }}
                    />
                </FormField>
                <FormField label="Deactivated">
                    <Checkbox
                        checked={isInactive}
                        color="primary"
                        onChange={(event) => {
                            setIsInactive(event.target.checked);
                        }}
                    />
                </FormField>
            </FormSection>
            <div>
                <Button
                    className={classes.formButton}
                    variant="contained"
                    color="primary"
                    disabled={submitDisabled}
                    onClick={submit}
                >
                    Submit
                </Button>
                <Button
                    className={classes.formButton}
                    variant="contained"
                    color="secondary"
                    onClick={close}
                >
                    Cancel
                </Button>
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme: ThemeOptions) => ({
    formButton: {
        marginTop: '10px',
        marginRight: '20px',
    },
    input: {
        padding: '5px',
    },
}));
