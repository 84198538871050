import React, { useState, useEffect } from 'react';
import * as core from '@material-ui/core';
import { Employee, EmployeeCreateDto } from '../../../dtos/employee';
import { UserLevel } from '../../../enums/userLevel';
import { FormField } from '../../shared/formField';
import { FormSection } from '../../shared/formSection';
import { PhoneNumberInput } from '../../shared/phoneNumberInput';
import { makeStyles } from '@material-ui/styles';
import { useApi } from '../../useApi';
import { AjaxResult } from '../../../enums/ajaxResult';
import { useSnackbar } from 'notistack';
import { ValidationErrors } from '../../shared/validationErrors';

import { UserLevelSelector } from "./UserLevelSelector";
import { EmployeeOrgSelectors } from "./EmployeeOrgSelectors";

export const EmployeeEditForm = ({
    closeEditForm,
    employee,
    onSaved,
}: {
    closeEditForm: () => void;
    employee: Employee;
    onSaved: (e: Employee) => void;
}) => {
    const { employeeApi } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [edited, setEdited] = useState<EmployeeCreateDto | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setEdited({
            address: employee.address,
            phone: employee.phoneNumber,
            email: employee.email,
            firstName: employee.firstName.trim(),
            lastName: employee.lastName.trim(),
            siteId: employee.siteId,
            organizationId: employee.organizationId,
            dealerId: employee.dealerId,
            userLevel: employee.roles[0] as UserLevel,
        });
    }, [employee]);

    const [errors, setErrors] = useState<string[]>([]);

    const save = () => {
        if (!edited) {
            return;
        }
        setLoading(true);
        setErrors([]);
        employeeApi.editEmployee({ ...edited }).then((r) => {
            if (r.result === AjaxResult.Success) {
                enqueueSnackbar("Changes to employee have been saved", { variant: "success" });
                if (r.data) {
                    onSaved(r.data);
                }
                closeEditForm();
            } else if (r.messages) {
                setErrors(r.messages);
            } else {
                enqueueSnackbar("Failed to save changes to employee", { variant: "error" });
            }
            setLoading(false);
        });
    };

    const submitDisabled = loading || !edited || !edited.firstName || !edited.lastName;

    return (
        <core.Dialog
            className={classes.employeeDialog}
            fullWidth={true}
            maxWidth={'md'}
            open={true}
            onClose={closeEditForm}
        >
            <core.DialogContent className={classes.employeeDialogContent}>
                {employee && edited && (
                    <FormSection headerText={'Employee Info'}>
                        <div>{employee.email}</div>
                        <FormField label="First Name" required={true}>
                            <core.FormControl>
                                <core.TextField
                                    value={edited.firstName}
                                    onChange={(e) =>
                                        setEdited({ ...edited, firstName: e.currentTarget.value })
                                    }
                                    variant="outlined"
                                    InputProps={{
                                        inputProps: { className: classes.input },
                                    }}
                                />
                            </core.FormControl>
                        </FormField>
                        <FormField label="Last Name" required={true}>
                            <core.FormControl>
                                <core.TextField
                                    value={edited.lastName}
                                    onChange={(e) =>
                                        setEdited({ ...edited, lastName: e.currentTarget.value })
                                    }
                                    variant="outlined"
                                    InputProps={{
                                        inputProps: { className: classes.input },
                                    }}
                                />
                            </core.FormControl>
                        </FormField>
                        <FormField label="Phone">
                            <core.FormControl>
                                <core.TextField
                                    value={edited.phone}
                                    onChange={(e) =>
                                        setEdited({ ...edited, phone: e.currentTarget.value })
                                    }
                                    InputProps={{
                                        inputComponent: PhoneNumberInput,
                                        inputProps: { className: classes.input },
                                    }}
                                    variant="outlined"
                                />
                            </core.FormControl>
                        </FormField>
                        <FormField label="Address">
                            <core.FormControl>
                                <core.TextField
                                    value={edited.address}
                                    onChange={(e) =>
                                        setEdited({ ...edited, address: e.currentTarget.value })
                                    }
                                    variant="outlined"
                                    InputProps={{
                                        inputProps: { className: classes.input },
                                    }}
                                />
                            </core.FormControl>
                        </FormField>

                        <FormField label="User Type" required={true}>
                            <UserLevelSelector
                                value={edited.userLevel}
                                onChange={(userLevel) => {
                                    setEdited({ ...edited, userLevel })
                                }}
                            />
                        </FormField>

                        <EmployeeOrgSelectors
                            selectedDealerId={edited.dealerId}
                            selectedOrganizationId={edited.organizationId}
                            selectedSiteId={edited.siteId}
                            selectedUserLevel={edited.userLevel}
                            onChange={(selected) => {
                                setEdited({
                                    ...edited,
                                    dealerId: selected.dealerId || undefined,
                                    organizationId: selected.orgId || undefined,
                                    siteId: selected.siteId || undefined,
                                });
                            }}
                        />
                        <ValidationErrors errors={errors} />
                    </FormSection>
                )}
            </core.DialogContent>
            <core.DialogActions className={classes.employeeDialogContent}>
                <core.Button color="primary" variant="contained" disabled={submitDisabled} onClick={save}>
                    {loading ? "Saving..." : "Save"}
                </core.Button>
                <core.Button color="secondary" variant="contained" onClick={closeEditForm}>
                    Cancel
                </core.Button>
            </core.DialogActions>
        </core.Dialog>
    );
};

const useStyles = makeStyles({
    input: {
        padding: '5px',
    },
    employeeDialog: {
        maxWidth: '750px',
        margin: 'auto',
    },
    employeeDialogContent: {
        maxWidth: '700px',
    },
});
