import { useTheme } from "@material-ui/core";
import React from "react";

import { Site } from "../../../../../dtos/site";

import { SensorResetButton } from "./SensorResetButton";
import { SystemRestartButton } from "./SystemRestartButton";

interface Props {
    site: Site;
}

export function TroubleshootSection({ site }: Props) {
    const theme = useTheme();

    return (
        <>
            <h1 style={{ color: theme.fontThemeColor3, margin: "40px 0 40px 0" }} >
                Troubleshooting
            </h1>

            <div
                style={{
                    gap: "10px",
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: "170px",
                }}
            >
                <SensorResetButton site={site} />
                <SystemRestartButton site={site} />
            </div>
        </>
    );
}
