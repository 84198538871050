import { Chip, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { RoomEntryState } from "../../../../enums/roomEntryState";
import { hubConnection } from "../../../../signalr/provider";

const useStyles = makeStyles({
    chip: {
        width: "200px",
        height: "auto",
        padding: "8px",
    },
    chipLabel: {
        whiteSpace: "normal",
        textAlign: "center",
    },
});

interface Props {
    siteId: number;
}

export function SiteRoomStatusIndicator({ siteId }: Props) {
    const [roomEntryState, setRoomEntryState] = useState(RoomEntryState.Idle);
    const [customerName, setCustomerName] = useState("");

    useEffect(() => {
        const callback = (
            eventSiteId: number,
            eventRoomState: RoomEntryState,
            eventCustomerName: string,
        ) => {
            if (eventSiteId !== siteId) return;
            setRoomEntryState(eventRoomState);
            setCustomerName(eventCustomerName);
        };

        hubConnection.on("roomStateChanged", callback);

        return () => {
            hubConnection.off("roomStateChanged", callback);
        };
    }, [siteId]);

    const classes = useStyles();

    return (
        <Chip
            color={getIndicatorColor(roomEntryState)}
            label={
                <div className={classes.chipLabel}>
                    {getIndicatorLabel(roomEntryState, customerName)}
                </div>
            }
            className={classes.chip}
        />
    );
}

function getIndicatorColor(roomEntryState: RoomEntryState) {
    switch (roomEntryState) {
        case RoomEntryState.EnteringRoom:
        case RoomEntryState.EnteringRoomDoorOpen:
        case RoomEntryState.InRoom:
        case RoomEntryState.ExitingRoom:
            return "primary";
        case RoomEntryState.Unauthorized:
        case RoomEntryState.BoxLeftOpen:
            return "secondary";
        case RoomEntryState.Idle:
        default:
            return "default";
    }
}

function getIndicatorLabel(
    roomEntryState: RoomEntryState,
    customerName = "unknown customer",
) {
    switch (roomEntryState) {
        case RoomEntryState.EnteringRoom:
            return `${customerName} entering room.`;
        case RoomEntryState.EnteringRoomDoorOpen:
            return `${customerName} entering room, door is open.`;
        case RoomEntryState.InRoom:
            return `Room occupied by ${customerName}`;
        case RoomEntryState.ExitingRoom:
            return `${customerName} exiting room.`;
        case RoomEntryState.Unauthorized:
            return "Unauthorized activity";
        case RoomEntryState.BoxLeftOpen:
            return "Box Left Open";
        case RoomEntryState.Idle:
        default:
            return "Idle";
    }
}
