import React from "react";
import { ValueType } from "react-select/src/types";

import { DialogSelect } from "../../dialogSelect";
import { FormField } from "../../formField";

import { useOrganizationOptions } from "./useOrganizationOptions";
import { useUserOrganizationOption } from "./useUserOrganizationOption";

interface Props {
    selectedOrganizationId: number | null;
    handleChange: (value: ValueType<{ value: number; label: string }>) => void;
    dealerId: number | null;
    showLabel: boolean;
    fullWidth: boolean;
    canGetOptions?: boolean;
    disabled: boolean;
    className?: string | undefined;
    required?: boolean;
}

export function OrganizationSelect({
    selectedOrganizationId,
    handleChange,
    dealerId,
    showLabel,
    fullWidth,
    canGetOptions = true,
    disabled,
    className,
    required = false,
}: Props) {
    const {
        selectedOrganizationOption,
        organizationOptions,
        isLoading,
    } = useOrganizationOptions(selectedOrganizationId, dealerId, canGetOptions);
    const userOrganizationOption = useUserOrganizationOption();

    const selectedOption = userOrganizationOption || selectedOrganizationOption;

    return (
        <FormField
            label={showLabel ? "Organization" : undefined}
            required={showLabel && required}
        >
            <DialogSelect
                key={JSON.stringify(selectedOption)} // this fixes Dialog rerendering on second pass of Undefined option
                options={organizationOptions}
                value={selectedOption}
                isDisabled={disabled}
                isClearable
                onChange={handleChange}
                placeholder="Select organization..."
                fullWidth={fullWidth}
                className={className}
                isLoading={isLoading}
            />
        </FormField>
    );
}
