import React, { useEffect, useState } from "react";

import { useAuth } from "../../features/auth/context";

import { Banner } from "./Banner";

export function BannerAboutDeactivation() {
    const [message, setMessage] = useState<string>();
    const {
        dealerId,
        orgId,
        siteId,
        isOrgActive,
        isSiteActive,
        isDealerActive,
    } = useAuth();

    useEffect(() => {
        if (dealerId !== null && !isDealerActive) {
            setMessage("Assigned Dealer is deactivated!")
        }
        if (orgId !== null && !isOrgActive) {
            setMessage("Assigned Organization is deactivated!")
        }
        if (siteId === null && !isSiteActive) {
            setMessage("Assigned Site is deactivated!")
        }
    }, [
        dealerId,
        orgId,
        siteId,
        isOrgActive,
        isSiteActive,
        isDealerActive,
    ])

    if (!message) {
        return null;
    }

    return (
        <Banner message={message} />
    );
}