import { Button, Dialog, DialogActions, DialogContent, FormControl, TextField } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";

import { Service } from "../../../dtos/service";
import { AjaxResult } from "../../../enums/ajaxResult";
import { TimeZone } from "../../../enums/timeZone";
import { useAuth } from "../../../features/auth/context";
import { FormSection } from "../../shared/formSection";
import { FormField } from "../../shared/formField";
import { useApi } from "../../useApi";
import { ValidationErrors } from "../../shared/validationErrors";

import { TimeZoneSelect } from "./TimeZoneSelect";

const incorrectGuidError = "Invalid Unique Id. There is no service matching this Service Id";
const missingOrgIdError = "Missing Organization ID";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onSiteAdded: () => void;
    serviceGuidFromParams: string | undefined;
    orgId: number | undefined
}

export function AddSiteDialog({
    isOpen,
    onClose,
    onSiteAdded,
    serviceGuidFromParams,
    orgId,
    children,
}: React.PropsWithChildren<Props>) {
    const { siteApi } = useApi();
    const { isGlobalAdmin, isDealerAdmin } = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    const [siteName, setSiteName] = useState("");
    const [serviceGuid, setServiceGuid] = useState<string>("");
    const [timeZone, setTimeZone] = useState<TimeZone>(TimeZone.UTC);
    const [selectedService, setSelectedService] = useState<Service | undefined>(undefined);
    const [availableServices, setAvailableServices] = useState<Service[] | null>(null);

    const [loading, setLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<string[]>([]);

    useEffect(() => {
        if (!isOpen) return;

        // only global admins can get these services, so we don't try to make this api call unless user is global admin
        if (!isGlobalAdmin && !isDealerAdmin) return;

        siteApi.getAvailableServices().then((r) => {
            setAvailableServices(r.data || []);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isGlobalAdmin, isDealerAdmin, isOpen]);

    const handleSiteNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSiteName(event.currentTarget.value)
    }, []);

    const handleSiteUniqueIdChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setServiceGuid(event.currentTarget.value);
    }, []);

    function saveNewSite() {
        setErrors([]);
        if (serviceGuid !== "" && !selectedService) {
            setErrors([incorrectGuidError]);
            return;
        }
        if (!orgId) {
            setErrors([missingOrgIdError]);
            return;
        }
        setLoading(true);
        siteApi
            .post({
                organizationId: orgId,
                name: siteName,
                emailAddresses: "",
                serviceId: selectedService ? selectedService.id : undefined,
                emailAlertsEnabled: true,
                referenceSiteId: "",
                timeZone: timeZone,
                maintenanceWindow: null,
            })
            .then((r) => {
                if (r.result === AjaxResult.Success) {
                    onSiteAdded();
                    setSiteName("");
                    setServiceGuid("");
                    onClose();
                    enqueueSnackbar("Site created");
                } else if (r.messages) {
                    setErrors(r.messages);
                } else {
                    enqueueSnackbar("ERROR: Failed to create site");
                }
                setLoading(false);
            });
    }

    useEffect(() => {
        if (serviceGuidFromParams) {
            setServiceGuid(serviceGuidFromParams);
        }
    }, [serviceGuidFromParams]);

    useEffect(() => {
        if (availableServices === null) return;
        const foundService = availableServices.find(it => it.serviceGuid === serviceGuid);

        if (!foundService) {
            setErrors([incorrectGuidError]);
        } else {
            setErrors([]);
            setSelectedService(foundService);
        }
    }, [serviceGuid, availableServices]);

    return (
        <Dialog open={isOpen} disablePortal>
            <DialogContent style={{ minWidth: "400px" }}>
                <FormSection headerText="Create New Site">
                    {children}

                    <FormField label="Site Name" required={true}>
                        <FormControl style={{ flexGrow: 1 }}>
                            <TextField
                                value={siteName}
                                onChange={handleSiteNameChange}
                                variant="outlined"
                                InputProps={{ inputProps: { style: { padding: "8px" } } }}
                            />
                        </FormControl>
                    </FormField>

                    <FormField label="Unique Id" required={true}>
                        <FormControl style={{ flexGrow: 1 }}>
                            <TextField
                                value={serviceGuid}
                                disabled={Boolean(serviceGuidFromParams)}
                                onChange={handleSiteUniqueIdChange}
                                variant="outlined"
                                InputProps={{ inputProps: { style: { padding: "8px" } } }}
                            />
                        </FormControl>
                    </FormField>

                    <FormField label="Time zone" required={true}>
                        <FormControl style={{ flexGrow: 1 }}>
                            <TimeZoneSelect
                                value={timeZone}
                                onChange={setTimeZone}
                            />
                        </FormControl>
                    </FormField>

                    <ValidationErrors errors={errors} />
                </FormSection>
            </DialogContent>

            <DialogActions>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={saveNewSite}
                    disabled={loading || !orgId || !siteName || !selectedService}
                >
                    {loading ? "Saving..." : "Save"}
                </Button>

                <Button
                    color="secondary"
                    variant="contained"
                    onClick={onClose}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}
