import React, { useCallback, useContext, useEffect, useState } from "react";

import SignalRContext from "../../signalr/context";
import { Site } from "../../dtos/site";

import { Banner } from "./Banner";

const SITE_ONLINE_CHECK_INTERVAL = 1000 * 30;

interface Props {
    site: Site | undefined;
}

export function SiteOfflineBanner({ site }: Props) {
    const { serviceStatus } = useContext(SignalRContext);
    const [siteOnline, setSiteOnline] = useState(true);

    const checkStatus = useCallback((siteId: number) => {
        setSiteOnline(Boolean(serviceStatus.get(siteId)))
    }, [serviceStatus]);

    useEffect(() => {
        if (!site) return;
        setSiteOnline(true);

        const interval = setInterval(() => {
            checkStatus(site.id);
        }, SITE_ONLINE_CHECK_INTERVAL);

        return () => {
            clearInterval(interval);
        };
    }, [site, checkStatus]);

    return !site || siteOnline ? null : (
        <Banner
            message={
                `It seems that Site ${site.name} ${site.uniqueSiteId
                } is currently offline or disconnected from the platform. You may encounter limited functionality for certain features.`
            }
        />
    );
}
