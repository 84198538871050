import React from "react";

const getCopyrightYear = () => new Date().getFullYear() >= 2024 ? new Date().getFullYear() : 2024;

export const Copyright = () => {
    return (
        <div style={{ padding: "5px", textAlign: "center" }}>
            © 2021-{getCopyrightYear()} Paznic, LLC
            <br />
            Pat. Nos. 11,282,317 & 11,580,805
        </div>
    );
};
