import { Icon, Paper, makeStyles } from "@material-ui/core";
import { WarningRounded } from "@material-ui/icons";
import React from "react";

const useStyles = makeStyles(() => ({
    container: {
        alignSelf: "stretch",
        padding: "10px",
        minWidth: "400px",
        display: "flex",
        gap: "10px",
        alignItems: "center",
        backgroundColor: "#fff9db",
        border: "1px solid #ffe066",
        borderRadius: "4px",
    },
    message: {
        margin: 0,
    },
}));

interface Props {
    message: string;
}

export function Banner({ message }: Props) {
    const classes = useStyles();
    return (
        <Paper className={classes.container} elevation={0}>
            <Icon>
                <WarningRounded />
            </Icon>
            <div>
                <p className={classes.message}>
                    {message}
                </p>
            </div>
        </Paper>
    );
}