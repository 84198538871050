import { Tooltip } from "@material-ui/core";
import React, { useMemo } from "react";

import { Event, EventForDisplay } from "../../../dtos/event";
import { AcsEventNameDisplay } from "../../../enums/acsEventName";
import { EventType } from "../../../enums/eventType";
import { ManagementEventNameDisplay } from "../../../enums/managementEventName";
import { SiteEventNameDisplay } from "../../../enums/SiteEventName";
import { getTimestamp } from "../../../utils/getTimestamp";
import { StandardTable } from "../../shared/table";

import { ViewEmployeeLink } from "./ViewEmployee";

interface Props {
    events: Event[];
    filterByLocalTime: boolean | null;
}

export function ReportLogTable({
    events,
    filterByLocalTime,
}: Props) {

    const reportLogsForDisplay: EventForDisplay[] = useMemo(
        () =>
            events.map((e) => {
                let specificEventName = undefined;
                switch (e.eventType) {
                    case EventType.AcsEvent:
                        specificEventName = AcsEventNameDisplay.get(e.eventName);
                        break;
                    case EventType.ManagementEvent:
                        specificEventName = ManagementEventNameDisplay.get(e.eventName);
                        break;
                    case EventType.SiteEvent:
                        specificEventName = SiteEventNameDisplay.get(e.eventName);
                        break;
                    default:
                        specificEventName = '';
                        break;
                }

                const timestamp = getTimestamp(filterByLocalTime, e.localTimestamp, e.timestamp);

                return {
                    accountType: e.accountType,
                    description: e.description,
                    eventName: specificEventName,
                    timestamp: timestamp,
                    user: e.employeeName || e.customerName,
                    userId: e.employeeId || e.customerId,
                    id: e.id,
                    isEmployee: Boolean(e.employeeId),
                    isDeleted: Boolean(e.isEmployeeDeleted || e.isCustomerDeleted),
                    uniqueSiteId: e.uniqueSiteId,
                    siteName: e.siteName,
                    organizationName: e.organizationName,
                };
            }),
        [events, filterByLocalTime]
    );

    return (
        <StandardTable>
            <thead>
                <tr>
                    <th>Site Id</th>
                    <th>User</th>
                    <th>User Id</th>
                    <th>Account Type</th>
                    <th>Timestamp</th>
                    <th>Event</th>
                    <th>Description</th>
                </tr>
            </thead>
            <tbody>
                {reportLogsForDisplay.map((e) => (
                    <tr key={`${e.id}_${e.eventName}`}>
                        <td>
                            <Tooltip title={`${e.organizationName ? e.organizationName + " - " : ""}${e.siteName}`}>
                                <span>{e.uniqueSiteId}</span>
                            </Tooltip>
                        </td>
                        <td>{e.user}</td>
                        <td>{<ViewEmployeeLink event={e} />}</td>
                        <td>{e.accountType}</td>
                        <td>{e.timestamp}</td>
                        <td>{e.eventName}</td>
                        <td>{e.description}</td>
                    </tr>
                ))}
            </tbody>
        </StandardTable>
    )
}
