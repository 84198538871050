export function getTargetLabel({
    dealerId,
    organizationId,
    siteId,
}: {
    dealerId: number | null | undefined,
    organizationId: number | null | undefined,
    siteId: number | null | undefined,

}): string {
    const dealerInfo = dealerId !== null ? `Dealer ${dealerId}` : "";
    const organizationInfo = organizationId !== null ? `Organization ${organizationId}` : "";
    const siteInfo = siteId !== null ? `Site ${siteId}` : "";
    return [dealerInfo, organizationInfo, siteInfo].filter(it => it !== "").join(" | ");
}
