import React, { useState, useEffect, useMemo } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from "@material-ui/core";
import { useIdleTimer } from "react-idle-timer";

import { IDLE_TIMEOUT_ENABLED, IDLE_PROMPT_TIMEOUT_SECONDS } from "../../../constants";
import { useAuth } from "../context";
import { useRefreshTokenRequest } from "../hooks/useRefreshTokenRequest";
import { getIdleTimeout } from "../utils/getIdleTimerConsts";
import { shouldSkipTokenRefresh } from "../utils/shouldSkipTokenRefresh";

const promptTimeout = 1000 * IDLE_PROMPT_TIMEOUT_SECONDS;
const eventsThrottle = 1000 * 10;
const syncTimers = 200; // recommended value

export function IdlePopup() {
    const { logout } = useAuth();
    const refreshTokenRequest = useRefreshTokenRequest();

    const [open, setOpen] = useState(false);
    const [promtTimeLeft, setPromtTimeLeft] = useState<number>(0);

    const idleTimeout = useMemo(getIdleTimeout, []);

    const onPrompt = () => {
        setOpen(true);
    };

    const onIdle = () => {
        logout();
    };

    const onAction = () => {
        if (!isPrompted() && !shouldSkipTokenRefresh(idleTimeout)) {
            refreshTokenRequest();
        }
    };

    const onMessage = (data: any) => {
        switch (data.action) {
            case "ACTIVATE":
                setOpen(false);
                activate();
                break;
            case "LOGOUT_USER":
                logout();
                break;
            default:
            // no op
        }
    };

    const { isPrompted, activate, pause, message } = useIdleTimer({
        crossTab: true,
        syncTimers,
        timeout: idleTimeout,
        promptTimeout,
        eventsThrottle,
        onPrompt,
        onIdle,
        onAction,
        onMessage,
    });

    const handleClose = () => {
        message({ action: "LOGOUT_USER" }, true);
    };

    const handleConfirm = () => {
        message({ action: "ACTIVATE" }, true);
        refreshTokenRequest(true);
    };

    useEffect(() => {
        let interval: null | ReturnType<typeof setInterval> = null;
        if (open) {
            setPromtTimeLeft(promptTimeout);
            interval = setInterval(() => {
                setPromtTimeLeft(prev => prev >= 1000 ? prev - 1000 : 0);
            }, 1000);
        }
        return () => {
            if (interval != null) {
                clearInterval(interval);
                setPromtTimeLeft(0);
            }
        };
    }, [open]);

    useEffect(() => {
        if (!IDLE_TIMEOUT_ENABLED) {
            pause();
        }
    }, [pause]);

    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
            disableBackdropClick
        >
            <DialogTitle id="alert-dialog-title">You're about to be signed out</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    For security reasons, your connection times out after you've been inactive for a
                    while. Click Continue to stay signed in.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {" "}
                <Button color="primary" variant="contained" onClick={handleConfirm}>
                    Continue ({promtTimeLeft / 1000})
                </Button>
                <Button onClick={handleClose} autoFocus>
                    Logoff
                </Button>
            </DialogActions>
        </Dialog>
    );
};
