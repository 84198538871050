import { FormControl } from "@material-ui/core";
import React, { useMemo } from "react";
import { ValueType } from "react-select/src/types";

import { UserLevel } from "../../../enums/userLevel";
import { useAuth } from "../../../features/auth/context";
import { DialogSelect } from "../../shared/dialogSelect";

interface Props {
    value: UserLevel | null;
    onChange: (value: UserLevel) => void;
}

export function UserLevelSelector({
    value,
    onChange,
}: Props) {
    const {
        isGlobalAdmin,
        isDealerAdmin,
        isOrgAdmin,
        isSiteAdmin,
    } = useAuth();

    const options: { label: string; value: string; }[] = useMemo(() => {
        const userLevels = getAvailableUserLevels(
            isGlobalAdmin,
            isDealerAdmin,
            isOrgAdmin,
            isSiteAdmin,
        );
        return userLevels.map((it) => ({ label: it, value: it }));
    }, [
        isGlobalAdmin,
        isDealerAdmin,
        isOrgAdmin,
        isSiteAdmin,
    ]);

    return (
        <FormControl style={{ minWidth: "300px" }}>
            <DialogSelect
                options={options}
                value={{ value: value || "", label: value || "" }}
                onChange={(
                    value: ValueType<{ value: string; label: string }>
                ) => {
                    const val = value as { value: string; label: string };
                    if (!val) {
                        return;
                    }
                    onChange(val.value as UserLevel)
                }}
            />
        </FormControl>
    );
}

function getAvailableUserLevels(
    isGlobalAdmin: boolean,
    isDealerAdmin: boolean,
    isOrganizationAdmin: boolean,
    isSiteAdmin: boolean,
): UserLevel[] {
    if (isGlobalAdmin) return [
        UserLevel.GlobalAdmin,
        UserLevel.DealerAdmin,
        UserLevel.OrgAdmin,
        UserLevel.SiteAdmin,
        UserLevel.SiteEmployee,
    ]
    if (isDealerAdmin) return [
        UserLevel.DealerAdmin,
        UserLevel.OrgAdmin,
        UserLevel.SiteAdmin,
        UserLevel.SiteEmployee,
    ]
    if (isOrganizationAdmin) return [
        UserLevel.OrgAdmin,
        UserLevel.SiteAdmin,
        UserLevel.SiteEmployee,
    ]
    if (isSiteAdmin) return [
        UserLevel.SiteAdmin,
        UserLevel.SiteEmployee,
    ]
    return [];
}
